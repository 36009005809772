import React from "react";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  Stack,
} from "@mui/material";
import _ from "lodash";

function CustomizedTable({ headers, values, align = "left", isDark = false }) {
  function renderTableCell(value) {
    if (_.isArray(value)) {
      return (
        <div>
          {value?.map((name) => (
            <Stack spacing={1}>
              <Typography color={isDark && "white"}>{name}</Typography>
            </Stack>
          ))}
        </div>
      );
    }
    return <Typography color={isDark && "white"}>{value}</Typography>;
  }

  function renderRow(row) {
    return Object.entries(row).map(([key, value], cellIndex) => {
      return (
        <TableCell
          key={cellIndex}
          align={cellIndex === 0 ? "start" : headers?.[key]?.align || align || "start"}
        >
          {renderTableCell(value)}
        </TableCell>
      );
    });
  }

  return (
    <Table>
      {headers && (
        <TableHead>
          <TableRow>
            {Object.values(headers).map((header, index) => (
              <TableCell
                key={index}
                align={index !== 0 ? header?.align || align : "start"}
              >
                <Typography
                  fontSize={16}
                  fontWeight={600}
                  color={isDark ? "white" : "inherit"}
                >
                  {header?.label}
                </Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
      )}
      <TableBody>
        {values?.map((item, index) => (
          <TableRow key={index}>{renderRow(item, index)}</TableRow>
        ))}
      </TableBody>
    </Table>
  );
}

export default CustomizedTable;
