import React from "react";
import { Typography, Box, Grid, Stack } from "@mui/material";
import { Trans } from "@lingui/macro";
import styled from "@emotion/styled";
import NewLayout from "../../components/NewLayout";
import Container from "../../components/Container";
import industryImage from "../../images/esg/icons/industry.png";
import climateImage from "../../images/esg/icons/climate.png";
import economicImage from "../../images/esg/icons/economic.png";
import educationImage from "../../images/esg/icons/education.png";
import goalsImage from "../../images/esg/icons/goals.png";
import healthImage from "../../images/esg/icons/health.png";
import inequalitiesImage from "../../images/esg/icons/inequalities.png";
import peaceImage from "../../images/esg/icons/peace.png";
import povertyImage from "../../images/esg/icons/poverty.png";
import productionImage from "../../images/esg/icons/production.png";
import BoxDetails from "../../components/BoxDetails";
import clientsImage from "../../images/esg/clients.png";
import employeesImage from "../../images/esg/employees.png";
import investorsImage from "../../images/esg/investors.png";
import partnersImage from "../../images/esg/partners.png";
import regulatorsImage from "../../images/esg/regulators.png";
import societyImage from "../../images/esg/society.png";
import ImageCardContent from "../../components/ImageCardContent";
import Table from "../../components/Table";

const contributionContent = () => [
  {
    title: <Trans>ESG Innovation</Trans>,
    subtitle: <Trans>Strategic</Trans>,
    content: [
      {
        image: industryImage,
        target: (
          <Trans>
            "Promote the adoption of environmentally sound technologies and
            processes"
          </Trans>
        ),
        allfunds: (
          <Trans>
            Allfunds is helping to transform the investment industry through
            digital solutions such as Blockchain.
          </Trans>
        ),
      },
      {
        image: productionImage,
        target: (
          <Trans>
            "Adopt sustainable practices that incorporate sustainability
            information into their reporting cycle"
          </Trans>
        ),
        allfunds: (
          <Trans>
            Allfunds provides comprehensive data to its clients facilitating
            investment decisions based on ESG criteria.
          </Trans>
        ),
      },
    ],
  },
  {
    title: <Trans>ESG Empowerment</Trans>,
    subtitle: <Trans>Priority</Trans>,
    content: [
      {
        image: economicImage,
        target: (
          <Trans>
            "Decent work for all women and men, including young people and
            people with disabilities, along with equal pay for work of equal
            value."
          </Trans>
        ),
        allfunds: (
          <Trans>
            Allfunds commits to creating added value for employees, ensuring the
            highest levels of technical competence and employability for ALL.
          </Trans>
        ),
      },
      {
        image: climateImage,
        target: (
          <Trans>
            "Improve education, awareness-raising, as well as human and
            institutional capacity on climate change mitigation, adaptation,
            impact reduction and early warning."
          </Trans>
        ),
        allfunds: (
          <Trans>
            In addition to environmental policies and procedures, Allfunds
            implements engaging awareness campaigns to address key environmental
            issues.
          </Trans>
        ),
      },
      {
        image: peaceImage,
        target: (
          <Trans>
            "Significantly reduce corruption and bribery in all its forms."
          </Trans>
        ),
        allfunds: (
          <Trans>
            As part of its activities and organization, Allfunds has solid
            policies and procedures to prevent corruption and bribery.
          </Trans>
        ),
      },
    ],
  },
  {
    title: <Trans>ESG Footprint</Trans>,
    subtitle: <Trans>Charity Fund</Trans>,
    content: [
      {
        image: povertyImage,
        target: (
          <Trans>
            "To build the resilience of the poor and people in vulnerable
            situations and reduce their exposure and vulnerability to
            climate-related extreme events along with and other economic, social
            and environmental disasters."
          </Trans>
        ),
        allfunds: (
          <Trans>
            Allfunds’ Charity Fund allocates part of its funds to projects
            dedicated to mitigating people's exposure to poverty and
            vulnerability.
          </Trans>
        ),
      },
      {
        image: healthImage,
        target: (
          <Trans>
            "To support research and development of vaccines and medicines for
            diseases… and facilitate access to affordable essential medicines
            and vaccines."
          </Trans>
        ),
        allfunds: (
          <Trans>
            Allfunds' Charity Fund contributes to play its part in the fight
            against cancer, autism, visual and psychosensory disability, ALS,
            Koolen-de Vries syndrome, cerebral palsy, and Dravet syndrome.
          </Trans>
        ),
      },
      {
        image: educationImage,
        target: (
          <Trans>
            "To eliminate gender disparities in education and ensure equal
            access to all levels of education and vocational training for
            vulnerable people, including people with disabilities, indigenous
            people and children in vulnerable situations."
          </Trans>
        ),
        allfunds: (
          <Trans>
            Allfunds’ Charity Fund contributes to ensuring training for
            vulnerable collectives, including people with disabilities and
            children.
          </Trans>
        ),
      },
      {
        image: inequalitiesImage,
        target: (
          <Trans>
            "To empower and promote the social, economic and political inclusion
            of all people, regardless of age, sex, disability, race, ethnicity,
            origin, religion, economic or other status."
          </Trans>
        ),
        allfunds: (
          <Trans>
            Allfunds’ Charity Fund collaborates with NGOs and associations in
            diverse projects for better labour inclusion and education.
          </Trans>
        ),
      },
      {
        image: goalsImage,
        target: (
          <Trans>
            "To mobilize additional financial resources from multiple sources
            for developing countries."
          </Trans>
        ),
        allfunds: (
          <Trans>
            Allfunds engages its stakeholders in diverse charity activities to
            increase its social footprint.
          </Trans>
        ),
      },
    ],
  },
];

const stakeholdersContent = () => [
  {
    title: <Trans>Employees</Trans>,
    image: employeesImage,
    content: (
      <Grid container>
        <Grid item xs={12} sm={4}>
          <Typography fontSize="18px !important" fontWeight={700}>
            <Trans>Stakeholder</Trans>
          </Typography>
          <ul>
            <li>
              <Trans>Direct employees (full time and part time)</Trans>
            </li>
            <li>
              <Trans>
                External employees (trainees, interns, subcontrators, temporary
                agencies)
              </Trans>
            </li>
          </ul>
          <Typography mt={3} fontSize="18px !important" fontWeight={700}>
            <Trans>Expectations</Trans>
          </Typography>
          <ul>
            <li>
              <Trans>Stable employment and fair compensation</Trans>
            </li>
            <li>
              <Trans>
                Professional development and the correct undertaking of their
                work through training activities
              </Trans>
            </li>
            <li>
              <Trans>Equal opportunities and treatment</Trans>
            </li>
            <li>
              <Trans>Work life-balance</Trans>
            </li>
            <li>
              <Trans>Safe and healthy work environment</Trans>
            </li>
          </ul>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography fontSize="18px !important" fontWeight={700}>
            <Trans>Value creation proposition</Trans>
          </Typography>
          <ul>
            <li>
              <Trans>
                Attractive compensation package that guarantees
                non-discrimination and recognises .experience and level of
                responsibility.
              </Trans>
            </li>
            <li>
              <Trans>
                Training and Development to upskill employees and foster
                individual development, to leverage and expand competencies and
                roles creating opportunities for growth within the organization
              </Trans>
            </li>
            <li>
              <Trans>Performance management process and feedback culture</Trans>
            </li>
            <li>
              <Trans>
                Definition of Allfunds’ Talent and Talent Identification process
              </Trans>
            </li>
            <li>
              <Trans>
                Diversity and Inclusion working environment in which all people
                are treated with respect, dignity and under equal conditions
              </Trans>
            </li>
            <li>
              <Trans>
                Work-life balance. Flexible working hours and digital
                disconnection measures are in place to improve the quality of
                life of its employees and their families
              </Trans>
            </li>
            <li>
              <Trans>
                Global Health, Safety and Wellbeing Policy that aims to ensure
                adequate resources, equipment and training for employees’ health
                and safety work practices and activities, complying with
                applicable local legislations
              </Trans>
            </li>
          </ul>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography fontSize="18px !important" fontWeight={700}>
            <Trans>Engagement action</Trans>
          </Typography>
          <ul>
            <li>
              <Trans>Launch of Human Capital Strategic Roadmap</Trans>
            </li>
            <li>
              <Trans>
                LTIP implementation and review of variable remuneration system
              </Trans>
            </li>
            <li>
              <Trans>
                Internal Mentoring Program (expert managers as mentors / high
                potential employees as mentees)
              </Trans>
            </li>
            <li>
              <Trans>
                Leadership Programs to reinforce Allfunds' leadership style
                mostly in middle management and new managers
              </Trans>
            </li>
            <li>
              <Trans>
                Implementation of a learning platform that offers a great
                variety of training that employees can follow “a la carte”
                autonomously
              </Trans>
            </li>
            <li>
              <Trans>
                Introduction of gamification methodology in order to better
                engage employees in learning paths
              </Trans>
            </li>
            <li>
              <Trans>
                Talent management: offering internal development opportunities
                withing the organization and acknowledging them through our
                intranet (vacancies covered internally and promotions)
              </Trans>
            </li>
          </ul>
        </Grid>
      </Grid>
    ),
  },
  {
    title: <Trans>Clients</Trans>,
    image: clientsImage,
    content: (
      <Grid container>
        <Grid item xs={12} sm={4}>
          <Typography fontSize="18px !important" fontWeight={700}>
            <Trans>Stakeholder</Trans>
          </Typography>
          <ul>
            <li>
              <Trans>Fund Houses</Trans>
            </li>
            <li>
              <Trans>Distributors</Trans>
            </li>
          </ul>
          <Typography mt={3} fontSize="18px !important" fontWeight={700}>
            <Trans>Expectations</Trans>
          </Typography>
          <ul>
            <li>
              <Trans>Excellent service (transparency and traceability</Trans>
            </li>
            <li>
              <Trans>
                Cybersecurity and data protection &gt; Support on Compliance &
                Regulatory framework
              </Trans>
            </li>
            <li>
              <Trans>
                Drive efficiency &gt; Improve sales &gt; Integration of ESG criteria
                in investments
              </Trans>
            </li>
          </ul>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography fontSize="18px !important" fontWeight={700}>
            <Trans>Value creation proposition</Trans>
          </Typography>
          <ul>
            <li>
              <Trans>
                Provide Fund Houses with a better understanding of common
                clients’ distribution activities
              </Trans>
            </li>
            <li>
              <Trans>
                Connect businesses with international markets through digital
                solutions, increasing control and reducing risks thanks to a
                global network
              </Trans>
            </li>
            <li>
              <Trans>
                Working continuously to innovate and develop digital solutions
                adapted to clients’ needs
              </Trans>
            </li>
            <li>
              <Trans>
                Contribute to the ‘democratisation’ of investment opportunities
                by providing access to premium products
              </Trans>
            </li>
            <li>
              <Trans>
                Operate an Information Security System that supports against
                possible threats, reducing the damage caused by incidents,
                ensuring the continuity of its services, and preserving the
                basic components of its security (confidentiality, integrity,
                availability, traceability and resilience)
              </Trans>
            </li>
            <li>
              <Trans>
                Transform the WealthTech world, empowering them with a unique
                combination of scale, experience and digital mindset.
              </Trans>
            </li>
          </ul>
        </Grid>
      </Grid>
    ),
  },
  {
    title: <Trans>Investor Community</Trans>,
    image: investorsImage,
    content: (
      <Grid container>
        <Grid item xs={12} sm={4}>
          <Typography fontSize="18px !important" fontWeight={700}>
            <Trans>Stakeholder</Trans>
          </Typography>
          <ul>
            <li>
              <Trans>Shareholders</Trans>
            </li>
            <li>
              <Trans>Investors</Trans>
            </li>
            <li>
              <Trans>Rating Agencies</Trans>
            </li>
            <li>
              <Trans>Analysts</Trans>
            </li>
            <li>
              <Trans>Proxy Advisors</Trans>
            </li>
          </ul>
          <Typography mt={3} fontSize="18px !important" fontWeight={700}>
            <Trans>Expectations</Trans>
          </Typography>
          <ul>
            <li>
              <Trans>Accesible and transparent information</Trans>
            </li>
            <li>
              <Trans>Deliver on Allfunds investment case</Trans>
            </li>
            <li>
              <Trans>
                Sound financial performance with a return on their investment
              </Trans>
            </li>
            <li>
              <Trans>Creation of long-term value </Trans>
            </li>
          </ul>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography fontSize="18px !important" fontWeight={700}>
            <Trans>Value creation proposition</Trans>
          </Typography>
          <ul>
            <li>
              <Trans>
                Long-term sustainable returns through attractive EBITDA margin
                and share price appreciation
              </Trans>
            </li>
            <li>
              <Trans>Progressive dividend policy</Trans>
            </li>
          </ul>
        </Grid>
      </Grid>
    ),
  },
  {
    title: <Trans>Regulators</Trans>,
    image: regulatorsImage,
    content: (
      <Grid container>
        <Grid item xs={12} sm={4}>
          <Typography fontSize="18px !important" fontWeight={700}>
            <Trans>Stakeholder</Trans>
          </Typography>
          <ul>
            <li>
              <Trans>Public Authorities and Supervisors</Trans>
            </li>
            <li>
              <Trans>Policymakers and Legislators</Trans>
            </li>
            <li>
              <Trans>Industry forums and working groups</Trans>
            </li>
          </ul>
          <Typography mt={3} fontSize="18px !important" fontWeight={700}>
            <Trans>Expectations</Trans>
          </Typography>
          <ul>
            <li>
              <Trans>
                Compliance with applicable regulations and best standards
              </Trans>
            </li>
            <li>
              <Trans>
                Constructive relationships with regulators and responsiveness to
                authorities’ requests
              </Trans>
            </li>
            <li>
              <Trans>Quality, transparency and timeliness in reporting</Trans>
            </li>
            <li>
              <Trans>
                Robustness of internal governance systems and documentation
              </Trans>
            </li>
            <li>
              <Trans>
                A culture of integrity and accountability from the top down
              </Trans>
            </li>
            <li>
              <Trans>
                Proactive follow-up of regulatory agenda and contribution to
                industry policymaking
              </Trans>
            </li>
            <li>
              <Trans>
                Payment of applicable taxes and social security contributions
              </Trans>
            </li>
          </ul>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography fontSize="18px !important" fontWeight={700}>
            <Trans>Value creation proposition</Trans>
          </Typography>
          <ul>
            <li>
              <Trans>
                Allfunds´ governance framework reflects applicable regulations
                and best standards and seeks to ensure excellence, robustness
                and prudence in business management
              </Trans>
            </li>
          </ul>
        </Grid>
      </Grid>
    ),
  },
  {
    title: <Trans>Business Partners</Trans>,
    image: partnersImage,
    content: (
      <Grid container>
        <Grid item xs={12} sm={4}>
          <Typography fontSize="18px !important" fontWeight={700}>
            <Trans>Stakeholder</Trans>
          </Typography>
          <ul>
            <li>
              <Trans>Strategic Business & Technological Partners</Trans>
            </li>
            <li>
              <Trans>General Suppliers</Trans>
            </li>
            <li>
              <Trans>Advisors & Consultants</Trans>
            </li>
          </ul>
          <Typography mt={3} fontSize="18px !important" fontWeight={700}>
            <Trans>Expectations</Trans>
          </Typography>
          <ul>
            <li>
              <Trans>Mutually beneficial and impactful partnerships </Trans>
            </li>
            <li>
              <Trans>Reciprocal and balanced agreements</Trans>
            </li>
            <li>
              <Trans>Loyalty and long-term relationships</Trans>
            </li>
            <li>
              <Trans>Ongoing communications and cultivated trust </Trans>
            </li>
            <li>
              <Trans>Flexible and innovative mindset </Trans>
            </li>
            <li>
              <Trans>Respect for laws and regulations</Trans>
            </li>
            <li>
              <Trans>Fulfilment of obligations and on-time payments </Trans>
            </li>
          </ul>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography fontSize="18px !important" fontWeight={700}>
            <Trans>Value creation proposition</Trans>
          </Typography>
          <ul>
            <li>
              <Trans>
                The Group core values of excellence, accountability, empowerment
                and inspiration drive all relationships with partners
              </Trans>
            </li>
            <li>
              <Trans>
                Allfunds' partnerships are aimed at transforming the WealthTech
                industry and thus to improve the entire distribution chain for
                the benefit of all parties
              </Trans>
            </li>
            <li>
              <Trans>
                The Group offers public recognition to partners and their
                contributions
              </Trans>
            </li>
            <li>
              <Trans>
                The Group promotes respect and protection of human and labour
                rights
              </Trans>
            </li>
            <li>
              <Trans>
                Allfunds' Code of Conduct aims to guarantee that suppliers are
                chosen with transparency and equal treatment, based on
                objective, weighted and ethical criteria
              </Trans>
            </li>
          </ul>
        </Grid>
      </Grid>
    ),
  },
  {
    title: <Trans>Society</Trans>,
    image: societyImage,
    content: (
      <Grid container>
        <Grid item xs={12} sm={4}>
          <Typography fontSize="18px !important" fontWeight={700}>
            <Trans>Stakeholder</Trans>
          </Typography>
          <ul>
            <li>
              <Trans>Non-Governmental Organisations (NGOs)</Trans>
            </li>
            <li>
              <Trans>Media &gt; Opinon leaders</Trans>
            </li>
            <li>
              <Trans>Civil society</Trans>
            </li>
            <li>
              <Trans>Environment</Trans>
            </li>
          </ul>
          <Typography mt={3} fontSize="18px !important" fontWeight={700}>
            <Trans>Expectations</Trans>
          </Typography>
          <ul>
            <li>
              <Trans>
                Contribute to the sustainable development of local communities
                and vulnerable groups in the countries where Allfunds operates
                and also in developing countries
              </Trans>
            </li>
            <li>
              <Trans>Clear and transparent communication</Trans>
            </li>
            <li>
              <Trans>
                Protect the environment: preventive approach, risk management,
                responsible use of natural resources and waste
              </Trans>
            </li>
          </ul>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography fontSize="18px !important" fontWeight={700}>
            <Trans>Value creation proposition</Trans>
          </Typography>
          <ul>
            <li>
              <Trans>
                Charity Fund Investment Policy, supervised by the Charity Fund
                Committee, to guarantee objectivity and maximise the impact of
                investments made. Focused on:
              </Trans>
            </li>
            <ul>
              <li>
                <Trans>Crowdfunding platform</Trans>
              </li>
              <li>
                <Trans>
                  Raise awareness among employees and other stakeholders within
                  the company’s scope of influence and control
                </Trans>
              </li>
              <li>
                <Trans>
                  Ensure equal opportunity of access to the Charity Fund and
                  report transparently on the results and positive impacts on
                  society
                </Trans>
              </li>
              <li>
                <Trans>
                  Give Allfunds employees the opportunity to propose social
                  projects to which they are locally committed
                </Trans>
              </li>
            </ul>
            <li>
              <Trans>
                Communication Protocol and Marketing and Communication
                Department to ensure clarity and consistency in corporate
                communication across the organisation and establish quality
                checks for external communications
              </Trans>
            </li>
            <li>
              <Trans>
                Climate Change Management and Environmental Policy to ensure
                well defined principles, criteria, rules and procedures that
                consolidate the prevention and reduction of the environmental
                impact of Allfunds’ business
              </Trans>
            </li>
          </ul>
        </Grid>
      </Grid>
    ),
  },
];

const kpiTables = () => [
  {
    title: <Trans>Economic</Trans>,
    content: {
      headers: {
        title: { label: "" },
        v2020: { label: <Trans>2020 Pro-Forma</Trans> },
        v2021: { label: "2021" },
        v2022: { label: "2022" },
      },
      data: [
        {
          title: <Trans>Economic performance</Trans>,
          values: [
            {
              title: <Trans>Net income (million €)</Trans>,
              v2020: "370.4",
              v2021: "505.7",
              v2022: "494.7",
            },
            {
              title: <Trans> Adjusted EBITDA (million €)</Trans>,
              v2020: "262.7",
              v2021: "367.2",
              v2022: "350.43",
            },
            {
              title: <Trans>Adjusted EBITDA margin (%)</Trans>,
              v2020: "70.9",
              v2021: "72.6",
              v2022: "70.8",
            },
            {
              title: <Trans>Adjusted expenditure (million €)</Trans>,
              v2020: "111.8",
              v2021: "145",
              v2022: "146.2",
            },
            {
              title: <Trans>Adjusted staff expenditure (million €)</Trans>,
              v2020: "69.0",
              v2021: "94.7",
              v2022: "83",
            },
            {
              title: (
                <Trans>
                  Other general and administration expenses (million €)
                </Trans>
              ),
              v2020: "42.7",
              v2021: "50.4",
              v2022: "63.2",
            },
            {
              title: <Trans>Capex (million €)</Trans>,
              v2020: "19.5",
              v2021: "26.6",
              v2022: "39.6",
            },
            {
              title: <Trans>Taxes paid (million €)</Trans>,
              v2020: "22.3",
              v2021: "116.9",
              v2022: "-67,26",
            },
          ],
        },
        {
          title: <Trans>Business</Trans>,
          values: [
            {
              title: <Trans>Offices (total)</Trans>,
              v2020: 15,
              v2021: 16,
              v2022: 17,
            },
            {
              title: <Trans>Clients (total)</Trans>,
              v2020: 760,
              v2021: 831,
              v2022: "",
            },
            {
              title: <Trans>Fund managers (total)</Trans>,
              v2020: "1,960",
              v2021: "2,340",
              v2022: ">3000",
            },
            {
              title: <Trans>Distributors (total)</Trans>,
              v2020: 760,
              v2021: 831,
              v2022: ">862",
            },

            {
              title: <Trans>Assets under administration (trillion €)</Trans>,
              v2020: "1.3",
              v2021: "1.5",
              v2022: "1.3",
            },
            {
              title: <Trans>Countries where we operate (total)</Trans>,
              v2020: "55",
              v2021: "62",
              v2022: "62",
            },
            {
              title: <Trans>Currencies with which we operate (total)</Trans>,
              v2020: "25",
              v2021: "25",
              v2022: "29",
            },
          ],
        },
      ],
    },
  },
  {
    title: <Trans>Environmental</Trans>,
    content: {
      headers: {
        title: { label: "" },
        v2020: { label: "2020" },
        v2021: { label: "2021" },
        v2022: { label: "2022" },
      },
      data: [
        {
          title: <Trans>Environmental management</Trans>,
          values: [
            {
              title: (
                <Trans>
                  Coverage of ISO 14001 certifications (%) <sup>(1)</sup>
                </Trans>
              ),
              v2020: "-",
              v2021: "47.15",
              v2022: "52.8",
            },
            {
              title: <Trans>Environmental resources invested (€)</Trans>,
              v2020: "198,611.24",
              v2021: "202,318.02",
              v2022: "217,357.00",
            },
          ],
        },
        {
          title: <Trans>Carbon footprint</Trans>,
          values: [
            {
              title: <Trans>Scope 1 CO2 emissions (tCO2e)</Trans>,
              v2020: 0,
              v2021: 7.31,
              v2022: 2.49,
            },
            {
              title: <Trans>Scope 2 CO2 emissions (tCO2e)</Trans>,
              v2020: 91.69,
              v2021: 30.39,
              v2022: 288.75,
            },
            {
              title: <Trans>Scope 3 CO2 emissions (tCO2e)(3)</Trans>,
              v2020: 393.82,
              v2021: "-",
              v2022: "1,277.46",
            },
            {
              title: <Trans>CO2 emissions intensity/employee</Trans>,
              v2020: 0.11,
              v2021: 0.04,
              v2022: 0.31,
            },
          ],
        },
        {
          title: <Trans>Use of natural resources</Trans>,
          values: [
            {
              title: <Trans>Water consumption (m3)</Trans>,
              v2020: "3,442.61",
              v2021: "3,343.08",
              v2022: "3,655.77",
            },
            {
              title: <Trans>Paper consumption (no. of sheets)</Trans>,
              v2020: "495,500.00",
              v2021: "165,343.00",
              v2022: "417,485",
            },
            {
              title: <Trans>Energy consumption (kWh)</Trans>,
              v2020: "1,109,936.34",
              v2021: "1,173,758.53",
              v2022: "1,364,008.27",
            },
            {
              title: (
                <Trans>
                  Electricity consumption from renewable sources (%)
                </Trans>
              ),
              v2020: "81.52",
              v2021: "92.89",
              v2022: "36.6",
            },
            {
              title: <Trans>Electricity generation (kWh)</Trans>,
              v2020: "20,101.62",
              v2021: "23,806.00",
              v2022: "21,762",
            },
          ],
        },
        {
          title: <Trans>Circular economy</Trans>,
          values: [
            {
              title: <Trans>Recycled paper (Tn)</Trans>,
              v2020: "10.54",
              v2021: "19.12",
              v2022: "45.33",
            },
            {
              title: (
                <Trans>
                  Waste from electrical and electronic equipment (Tn)
                </Trans>
              ),
              v2020: "2.15",
              v2021: "0.53",
              v2022: "0.001",
            },
            {
              title: <Trans>Fluorescent tubes and lights (kg)</Trans>,
              v2020: "-",
              v2021: "29",
              v2022: "14.65",
            },
          ],
          description: (
            <div>
              <Trans>
                (1) Coverage of ISO14001 certifications based on employees per
                Allfunds offices
              </Trans>
            </div>
          ),
        },
      ],
    },
  },
  {
    title: <Trans>Social</Trans>,
    content: {
      headers: {
        title: { label: "" },
        v2020: { label: "2020" },
        v2021: { label: "2021" },
        v2022: { label: "2022" },
      },
      data: [
        {
          title: <Trans>Human capital</Trans>,
          values: [
            {
              title: <Trans>Direct employees (31 December) (total)</Trans>,
              v2020: 834,
              v2021: 861,
              v2022: 1.031,
            },
            {
              title: (
                <Trans>Employees with a permanent (open-ended) contract</Trans>
              ),
              v2020: 771,
              v2021: 765,
              v2022: 962,
            },
            {
              title: (
                <Trans>Gender diversity in the workforce (women) (%)</Trans>
              ),
              v2020: "45.00",
              v2021: "46.00",
              v2022: "42.00",
            },
            {
              title: (
                <Trans>
                  Gender diversity in management positions (women) (%)
                </Trans>
              ),
              v2020: 25.0,
              v2021: 27.0,
              v2022: "32.80",
            },
            {
              title: <Trans>Functional diversity (%)</Trans>,
              v2020: 1.55,
              v2021: 1.63,
              v2022: 1.89,
            },
            {
              title: (
                <Trans>
                  Average hours of training per employee (total hours)
                </Trans>
              ),
              v2020: 7,
              v2021: 9.8,
              v2022: 10.04,
            },
            {
              title: <Trans>Retention rate (%)</Trans>,
              v2020: 92.80,
              v2021: 92.82,
              v2022: 87.20,
            },
            {
              title: (
                <>
                  <Trans>Absenteeism (hours)</Trans>
                  <sup>(1)</sup>
                </>
              ),
              v2020: "46,331.00",
              v2021: "86,115.00",
              v2022: "55,828.00",
            },
            {
              title: <Trans>Lost time injuries (total)</Trans>,
              v2020: 0,
              v2021: 0,
              v2022: 0,
            },
            {
              title: <Trans>Occupational diseases (total)</Trans>,
              v2020: 0,
              v2021: 0,
              v2022: 0,
            },
            {
              title: (
                <Trans>
                  Employees subject to collective bargaining agreement (%){" "}
                  <sup>(2)</sup>
                </Trans>
              ),
              v2020: 64.0,
              v2021: 66.78,
              v2022: 70.42,
            },
          ],
        },
        {
          title: <Trans>Solidarity</Trans>,
          values: [
            {
              title: <Trans>Social contribution (€)</Trans>,
              v2020: "",
              v2021: "",
              v2022: "",
            },
            {
              title: <Trans>Economic</Trans>,
              v2020: "304,241",
              v2021: "200,236",
              v2022: "198,408",
            },
            {
              title: <Trans>Time</Trans>,
              v2020: "-",
              v2021: "14,400",
              v2022: "16,818",
            },
            {
              title: <Trans>In kind</Trans>,
              v2020: "-",
              v2021: "71,400",
              v2022: "2,320.00",
            },
            {
              title: <Trans>Beneficiary social organisations (total)</Trans>,
              v2020: 12,
              v2021: 49,
              v2022: 40,
            },
            {
              title: (
                <Trans>
                  Employees participating in volunteer actions (no.)
                </Trans>
              ),
              v2020: "-",
              v2021: 285,
              v2022: 268,
            },
          ],
        },
        {
          title: <Trans>Supply chain</Trans>,
          values: [
            {
              title: <Trans>Approved suppliers (total)</Trans>,
              v2020: 789,
              v2021: "1,063",
              v2022: "1,012",
            },
            {
              title: <Trans>Average payment to suppliers (total days)</Trans>,
              v2020: 24,
              v2021: 27.9,
              v2022: 28.3,
            },
          ],
          description: (
            <>
              <div>
                <Trans>
                  (1) The increase in 2021 is due to the fact that all the staff
                  in Poland and part of the staff in Italy joined Allfunds in
                  October 2020. Thus, only the absence hours of these sites from
                  October to December were included, thus not including the year
                  2020 in full. The scope in 2020 changed, since 5 new offices
                  that do not have a collective bargaining agreement were
                  incorporated
                </Trans>
              </div>
              <div>
                <Trans>
                  (2) Calculated according to the 2018 version of the
                  international standard ISO 14064-1
                </Trans>
              </div>
            </>
          ),
        },
      ],
    },
  },
  {
    title: <Trans>Governance</Trans>,
    content: {
      headers: {
        title: { label: "" },
        v2020: { label: "2020" },
        v2021: { label: "2021" },
        v2022: { label: "2022" },
      },
      data: [
        {
          title: <Trans>Governance System</Trans>,
          values: [
            {
              title: <Trans>Number of Directors (total)</Trans>,
              v2020: 8,
              v2021: 15,
              v2022: 13,
            },
            {
              title: <Trans>Independent directors (%)</Trans>,
              v2020: 25.0,
              v2021: 40.0,
              v2022: 54,
            },
            {
              title: <Trans>Gender diversity in the Board (women) (%)</Trans>,
              v2020: 25.0,
              v2021: 27.0,
              v2022: 31,
            },
            {
              title: <Trans>Frequency of Board meetings (total) </Trans>,
              v2020: "-",
              v2021: 8,
              v2022: 6,
            },
            {
              title: <Trans>Attendance of Directors (%)</Trans>,
              v2020: "-",
              v2021: 98.21,
              v2022: 100,
            },
            {
              title: (
                <Trans>
                  Compliance with the Dutch Corporate Governance Code (%){" "}
                  <sup>(1)</sup>
                </Trans>
              ),
              v2020: "-",
              v2021: 92.0,
              v2022: 96.5,
            },
          ],
        },
        {
          title: <Trans>Regulatory compliance, ethics and human rights</Trans>,
          values: [
            {
              title: (
                <Trans>
                  Notifications received through reporting channels (total)
                </Trans>
              ),
              v2020: 0,
              v2021: 0,
              v2022: 0,
            },
            {
              title: (
                <Trans>
                  Employees trained in topics related to ethical compliance
                  (total)
                </Trans>
              ),
              v2020: 757,
              v2021: 743,
              v2022: 979,
            },
          ],
          description: (
            <div>
              <Trans>
                (1) The number of recommendations that are fulfilled out of
                the total recommendations applicable to Allfunds. There is no
                data for 2019 and 2020, since Allfunds was not listed on
                Euronext.
              </Trans>
            </div>
          ),
        },
      ],
    },
  },
];

function OurEsgApproachPage() {
  return (
    <NewLayout byDefault apps="esg.approach_esg">
      <Container pb={8}>
        <Typography mt={3} mb={2} variant="h2">
          <Trans>Our ESG Approach</Trans>
        </Typography>
        <Typography>
          <Trans>
            At Allfunds, we believe in the right balance between economic,
            social, ethical and environmental issues to achieve long-term
            business sustainability. We apply Environmental, Social, and
            Governance principles to our day-to-day operations and business
            development. In order to continue to advance and remain at the
            cutting edge, we seek to integrate the maximum standards applicable
            to our business and take our stakeholders demands into account.
          </Trans>
        </Typography>
        <Box mt={6}>
          <Typography mb={2} variant="h3">
            <Trans>Contribution to UN SDGs</Trans>
          </Typography>
          {contributionContent().map(({ title, subtitle, content }) => (
            <BoxDetails title={title} subtitle={subtitle} mb={2}>
              {content.map(({ image, target, allfunds }) => (
                <StyledGrid container alignItems="center">
                  <Grid
                    item
                    xs={12}
                    sm={2}
                    display="flex"
                    justifyContent="center"
                  >
                    <img
                      alt=""
                      width="120px"
                      height="120px"
                      src={image}
                      style={{ borderRadius: "5px" }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={5}>
                    <Typography fontWeight={600} fontSize="20px !important">
                      <Trans>Target</Trans>
                    </Typography>
                    {target}
                  </Grid>
                  <Grid item xs={12} sm={5}>
                    <Typography fontWeight={600} fontSize="20px !important">
                      <Trans>Allfunds</Trans>
                    </Typography>
                    {allfunds}
                  </Grid>
                </StyledGrid>
              ))}
            </BoxDetails>
          ))}
        </Box>
        <Box mt={6}>
          <Typography mb={2} variant="h3">
            <Trans>Stakeholder Engagement</Trans>
          </Typography>
          <Grid container>
            {stakeholdersContent().map(({ title, image, content }) => (
              <Grid item xs={12} sm={4} sx={{}}>
                <ImageCardContent
                  title={title}
                  image={image}
                  content={content}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      </Container>
      <Box sx={{ background: (theme) => theme.palette.primary.main }}>
        <Container py={5}>
          <WhiteTypography mb={2} variant="h3">
            <Trans>KPIs</Trans>
          </WhiteTypography>
          <Stack spacing={2}>
            {kpiTables().map(({ title, content: { headers, data } }) => (
              <BoxDetails isLigth title={title}>
                {data.map(({ title, values, description }, index) => (
                  <Box mt={index !== 0 && 4}>
                    <WhiteTypography fontWeight={700}>{title}</WhiteTypography>
                    <Table
                      isDark
                      headers={index === 0 && headers}
                      values={values}
                      align="center"
                    />
                    <WhiteTypography fontSize={10} mt={2}>
                      {description}
                    </WhiteTypography>
                  </Box>
                ))}
              </BoxDetails>
            ))}
          </Stack>
        </Container>
      </Box>
    </NewLayout>
  );
}

const WhiteTypography = styled(Typography)`
  color: white;
`;

const StyledGrid = styled(Grid)(
  ({ theme }) => `
  padding: ${theme.spacing(2)} 0;
  > div {
    padding: 0 ${theme.spacing(1)};
    ${theme.breakpoints.down("sm")} {
      padding: ${theme.spacing(1)} 0;
    }
  }
  &:not(:last-child) {
    border-bottom: 2px solid #e6e6e6;
  }
`,
);

export default OurEsgApproachPage;
